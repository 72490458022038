import React, { useEffect } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { Button } from "antd";
import { DownOutlined } from "@ant-design/icons";
import Dropdownmenu from "./Dropdownmenu";

// Mobil cihaz kontrol fonksiyonunu bir yardımcı fonksiyon olarak dışa aktarabiliriz.
const isMobile = () => {
  return /Mobi|Android/i.test(navigator.userAgent);
};

function Header() {
  const navigate = useNavigate();

  useEffect(() => {
    // Menü öğelerinin tıklama olaylarını dinleyen bir fonksiyon.
    const handleMenuClick = (e) => {
      const title = e.target.innerText;
      navigate({
        pathname: "/Products",
        search: createSearchParams({ title }).toString(),
      });
    };

    // Menüyü bulma ve olay dinleyicilerini ekleme.
    const attachMenuListeners = () => {
      const $menuElements = document.querySelectorAll(
        ".ant-dropdown-menu-item-group > div"
      );
      $menuElements.forEach((element) => {
        element.addEventListener("click", handleMenuClick);
      });
    };

    // DOM güncellemeleri için gözlemci ayarlama.
    const observer = new MutationObserver(attachMenuListeners);
    observer.observe(document.body, { childList: true, subtree: true });

    // Cleanup function to remove observers and event listeners.
    return () => {
      observer.disconnect();
      document
        .querySelectorAll(".ant-dropdown-menu-item-group > div")
        .forEach((element) => {
          element.removeEventListener("click", handleMenuClick);
        });
    };
  }, [navigate]);

  return (
    <div className="headerStyle">
      <div className="headerLeft">
        <h4 className="alpaText" onClick={() => navigate("/")}>
          alpa
          <span style={{ fontSize: "9px", verticalAlign: "text-top" }}>®</span>
        </h4>
        <h1 className="almilonText" onClick={() => navigate("/")}>
          ALMILON
        </h1>
      </div>
      <div className="headerRight">
        <Dropdownmenu />
        <Button
          className="menuButtons"
          onClick={() => navigate("/AboutUs")}
          type="text"
        >
          About Us
        </Button>
        <Button
          className="menuButtons"
          onClick={() => navigate("/Contact")}
          type="text"
        >
          Contact
        </Button>
      </div>
    </div>
  );
}

export default Header;
