import React from "react";

function Productcard(props) {
  return (
    <>
      <div className="productcard">
        <h4 style={{ color: "#F6FB6A" }}>{props.data.title}</h4>
        <h5>{props.data.description}</h5>
        <h6>{props.data.package}</h6>
        <div>{props.data.image}</div>
      </div>
    </>
  );
}

export default Productcard;
