import React, { useEffect } from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Productcard from "../Components/Productcard";
import { useSearchParams } from "react-router-dom";
import $ from "jquery";
import BackToTop from "../Components/BackToTop";

// menüdeki ürün ismiyle ürünlerdekinin aynı olması gerekiyor çalışması için (küçük-büyük harf vs)
function Products() {
  const [searchParams] = useSearchParams();
  const productTitle = searchParams.get("title");

  // setTimeout(() => {
  //   window.addEventListener("scroll", () => {
  //     document.querySelector(".ant-dropdown").style.top = "-500px";
  //   });
  // }, 1000);

  useEffect(() => {
    if (!!productTitle) {
      const $elementToScroll =
        $(`.productcard:contains(${productTitle})`)[0] || $(`body`)[0];

      setTimeout(
        () =>
          $elementToScroll.scrollIntoView({
            behavior: "smooth",
          }),
        500
      );
    }
  }, [window.location.href]);

  let products = {
    antiSeizeCopperPaste: {
      title: "Anti-Seize Copper Paste",
      description: `Prevents seizure, galling and corrosion Excellent resistance to water, even when submerged \n Highly resistant to salt water environments\n Lead-free paste formulation\n Excellent temperature range -50ºC to +1100ºC\n Coefficient of friction 0.15`,
      package: "500gr Box",
      image: " ",
    },
    siliconeGrease: {
      title: "Silicone Grease",
      description:
        "Silicone grease can be used for a wide temperature range, generally from -60°C to 300°C even with varying pressure or load conditions.\n Silicone grease with PTFE filler can be used for electrical applications due to their electrically insulating properties.\n The silicone oil present in the grease is water repellant, thus, can be used in applications where long-term protection against oxidation is desired.\n In some cases, silicone lubricant grease with high viscosity can also be used as a sealant.\n The non-toxic nature of silicone grease makes it possible to use in medical applications as well as in food-grade facilities.",
      package: "500gr box, 1kg Box, 10kg Pail, 25kg Pail",
      image: " ",
    },
    graphiteSiliconeGrease: {
      title: "Graphite Silicone Grease",
      description:
        "High Temperature Graphite Grease provides a residual film of low coefficient of friction which enhances its recommendation for industrial application. This graphite-based grease forms a lubricating film that prevents metal-to-metal contact by separating frictional surfaces. It is excellent for use where operating temperatures reach up to 525 °F (275 °C).",
      package: "500gr box, 1kg Box, 10kg Pail, 25kg Pail",
      image: " ",
    },
    contactSpray: {
      title: "Contact Spray",
      description:
        "Contact Cleaner is a plastic-safe general-purpose evaporating cleaner and degreaser for use on sensitive electronic and electrical equipment. It is formulated to quickly penetrate into hard-to-reach areas and effectively flush away carbon deposits, dirt, light oils, dust, lint and other light contaminants.",
      package: "200ml, 400ml, 500ml",
      image: " ",
    },
    degreaserSpray: {
      title: "Degreaser Spray",
      description:
        " Degreaser Spray is a non-flammable heavy-duty evaporating cleaner and degreaser for use on electric motors and other heavy-duty electrical and mechanical equipment. The stabilised fast-acting solvent blend effectively removes wax, grease, oil, dirt, moisture and foreign matter that are common causes of current leakages and electric inefficiencies. It minimises downtime by cleaning low-voltage motors and equipment while in operation.",
      package: "200ml, 400ml, 500ml",
      image: " ",
    },
    smokeTestSpray: {
      title: "Smoke Test Spray",
      description:
        "Smoke Test Spray simulates smoke to conveniently test the complete functional ability of commercial and residential smoke detectors. Unlike the test button on the detector, it not only checks the batteries but also tests for obstruction or debris that may clog detector vents and prevent smoke from entering the sensing chamber.",
      package: "200ml, 400ml, 500ml",
      image: " ",
    },

    rustRemoverSpray: {
      title: "Rust Remover Spray",
      description:
        " Rust Remover Spray is an industrial strength, low viscosity lubricant and penetrant designed to quickly loosen and free seized, bound or frozen fasteners. Spray is quickly permeates rust, scale, gum, grease and corrosion with a low surface tension liquid penetrant formula. It creeps into cracks, seams, threads and joints with great efficiency and leaves a thin non-drying colourless film with outstanding lubrication and corrosion inhibiting properties",
      package: "200ml, 400ml, 500ml",
      image: " ",
    },

    brakeCleanerSpray: {
      title: "Brake Cleaner Spray",
      description:
        " Brake Cleaner Spray is a powerful, heavy duty cleaner and degreaser for brake, clutch parts and general mechanical equipment. It is formulated to quickly and safely dissolve and flush away grease, oil, brake fluid, transmission fluid, hardened deposits and other contaminants, without the need for disassembly - reducing maintenance costs.",
      package: "200ml, 400ml, 500ml",
      image: " ",
    },

    waterFindingPaste: {
      title: "Water Finding Paste",
      description:
        "Water Finding Paste is a useful tool to detect the presence of water in your fuel tank. Apply it to a piece of string or a clean rod, and lower it to the bottom of the tank. The brown paste will turn red when in contact with water, indicating the depth of the water in the tank.",
      package: "70gr",
      image: " ",
    },
    siliconeHeatTransferPaste: {
      title: "Silicone Heat Transfer Paste",
      description:
        "It is widely used to improve heat flow for spark plug, between heat sinks and heat generating electronic components such as CPU's, GPU's, and power components. Silicone thermal greases are preferred in high operating temperature applications where silicone migration is not a concern.",
      package: "500gr box, 1kg Box, 10kg Pail, 25kg Pail",
      image: " ",
    },
    solderingPaste: {
      title: "Soldering Paste",
      description:
        "Flux based on acid with pasty consistency for soft soldering of copper and copper alloys for copper pipe installations, radiator construction, plumbing, dip soldering, fitting manufacturing and other solder works. ",
      package: "70gr, 500gr, 1kg",
      image: " ",
    },
  };
  return (
    <>
      <Header />
      <div className="productBody">
        <Productcard data={products.antiSeizeCopperPaste} />
        <Productcard data={products.siliconeGrease} />
        <Productcard data={products.graphiteSiliconeGrease} />
        <Productcard data={products.contactSpray} />
        <Productcard data={products.degreaserSpray} />
        <Productcard data={products.smokeTestSpray} />
        <Productcard data={products.rustRemoverSpray} />
        <Productcard data={products.brakeCleanerSpray} />
        <Productcard data={products.waterFindingPaste} />
        <Productcard data={products.siliconeHeatTransferPaste} />
        <Productcard data={products.solderingPaste} />
      </div>
      {/* <BackToTop /> */}
    </>
  );
}

export default Products;
