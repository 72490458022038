import React from "react";
import { useNavigate } from "react-router-dom";

function Notfound() {
  const navigate = useNavigate();
  return (
    <>
      <div className="error-page">
        <h2>404</h2>
        <h3>Page Not Found</h3>

        <button
          style={{
            backgroundColor: "#6c0d31",
            border: "none",
            borderRadius: "20px",
            color: "wheat",
            width: "100px",
            height: "25px",
            cursor: "pointer",
          }}
          onClick={() => {
            navigate("/");
          }}
        >
          Home
        </button>
      </div>
    </>
  );
}

export default Notfound;
