import "./App.css";

import Home from "./Pages/Home";
import Products from "./Pages/Products";
import AboutUs from "./Pages/AboutUs";
import Contact from "./Pages/Contact";
import Notfound from "./Components/Notfound";
import { Routes, Route } from "react-router-dom";

function App() {
  const styleLink = document.createElement("link");

  styleLink.rel = "stylesheet";
  styleLink.href = "https://cdn.jsdelivr.net/npm/semantic-ui/dist/semantic.min.css";

  document.head.appendChild(styleLink);

  return (
    <>
      <div className="App">
        <Routes>
          <Route exact path="/" element={<Home />} />
          {/* <Route path="/home" element={<Home />} /> */}
          <Route path="/products" element={<Products />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<Notfound1 />} />
        </Routes>
      </div>
    </>
  );
}
function Notfound1() {
  return <Notfound />;
}

export default App;
