import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import logo from "../assets/alpa.png";

function AboutUs() {
  return (
    <>
      <Header />
      <div
        className="aboutus"
        style={{ minHeight: "100vh", paddingTop: "130px" }}
      >
        {/* <img src={logo} style={{ width: "200px", marginTop: "20px" }} alt="" /> */}
        <br />
        <h4>What is our company?</h4>
        Alpa Endustri was established in 1982 and is a family-owned business
        with its headquarters in Istanbul, Turkey. We manufacture products for
        industry-leading companies in the fields of chemistry, electronics,
        elastomers and tribology. We are committed to ongoing product
        development to ensure our customers receive the highest quality and meet
        their exacting standards. We are always eager to take on new challenges
        and to serve as a solution partner, leveraging our extensive expertise.
        Our primary objectives are to provide:
        <br />
        <br />• The highest quality products <br />• Sustainable solutions{" "}
        <br />• Competitive pricing
        <br />
        <br />
        <h4>Why choose Alpa Industry?</h4>
        Make your work easier with Alpa Industry. With a variety of quality
        products, we have a technical solution for almost any individual/special
        situation. We provide the right oils, greases, pastes, dry lubricants,
        maintenance products/cleaners and corrosion protection for the industry
        or application.
      </div>
    </>
  );
}

export default AboutUs;
