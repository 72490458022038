import React, { useEffect } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { Dropdown, Menu } from "semantic-ui-react";

// Dropdown menüsünün seçenekleri.
const options = [
  {
    key: "1",
    text: "Anti-Seize Copper Paste",
    value: "Anti-Seize Copper Paste",
  },
  { key: "2", text: "Silicone Grease", value: "Silicone Grease" },
  {
    key: "3",
    text: "Graphite Silicone Grease",
    value: "Graphite Silicone Grease",
  },
  { key: "4", text: "Contact Spray", value: "Contact Spray" },
  { key: "5", text: "Degreaser Spray", value: "Degreaser Spray" },
  { key: "6", text: "Smoke Test Spray", value: "Smoke Test Spray" },
  { key: "7", text: "Rust Remover Spray", value: "Rust Remover Spray" },
  { key: "8", text: "Brake Cleaner Spray", value: "Brake Cleaner Spray" },
  { key: "9", text: "Water Finding Paste", value: "Water Finding Paste" },
  {
    key: "10",
    text: "Silicone Heat Transfer Paste",
    value: "Silicone Heat Transfer Paste",
  },
  { key: "11", text: "Soldering Paste", value: "Soldering Paste" },
];

const Dropdownmenu = () => {
  const navigate = useNavigate();

  // Menü tıklama olaylarını işleyen fonksiyon.
  const handleItemClick = (e, { value }) => {
    navigate({
      pathname: "/Products",
      search: createSearchParams({ title: value }).toString(),
    });
  };

  useEffect(() => {
    // DOM değişikliklerini izlemek için bir MutationObserver kullanıyoruz.
    const observer = new MutationObserver(() => {
      const menuItems = document.querySelectorAll(".menu .transition > .item");
      menuItems.forEach((item) => {
        item.addEventListener("click", (e) =>
          handleItemClick(e, { value: e.target.innerText })
        );
      });
    });

    observer.observe(document.body, { childList: true, subtree: true });

    // Cleanup fonksiyonu
    return () => {
      observer.disconnect();
    };
  }, [navigate]);

  return (
    <Menu compact>
      <Dropdown
        text="Products"
        options={options}
        simple
        item
        onChange={handleItemClick}
      />
    </Menu>
  );
};

export default Dropdownmenu;
