import React from "react";
import Header from "../Components/Header";
import Body from "../Components/Body";
//import Footer from "../Components/Footer";
//import { pexelsFetchVideoURL } from "../pexels.jsx";
import video from "../assets/video.mov";

function Home() {
  //pexelsFetchVideoURL("15460874");

  //const videoLinks = "https://player.vimeo.com/progressive_redirect/playback/796351956/rendition/720p/file.mp4?loc=external&oauth2_token_id=57447761&signature=6333c7f36b3d194d3a63e834cd1056b78d5d2b630b7b1d5e77120a4613ee97e3";
  return (
    <>
      <div className="videoWrapper" style={{ overflow: "hidden" }}>
        <video height="100%" controls={false} autoPlay muted loop playsInline>
          <source src={video} />
        </video>
      </div>
      <Header />
      <Body />
    </>
  );
}

export default Home;
